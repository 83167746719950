import gql from 'graphql-tag';

const MessagesQuery = gql`
  query MyQuery($conversationId: String!) {
    getMessages(conversationId: $conversationId) {
      items {
        body
        conversationId
        createdAt
        id
        sender
        userName
      }
      nextToken
    }
  }
`;

export default MessagesQuery;
