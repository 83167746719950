import { apiClient, handleResp } from './utils';

export default {
  async getPollingInfo(eventId) {
    return handleResp((await apiClient()).get('/event/' + eventId + '/poll'));
  },
  async getPollingAnswer(eventId, pollId) {
    return handleResp((await apiClient()).get('/event/' + eventId + '/poll/' + pollId + '/answer'));
  },
  async createPollingAnswer(eventId, pollId, data) {
    return handleResp((await apiClient()).post('/event/' + eventId + '/poll/' + pollId + '/answer', data));
  },
};
