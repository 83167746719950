<template>
  <div>
    <div v-if="invalidEvent" class="d-flex justify-center align-center mx-3" style="height: 100vh">
      <Alert message="You need to register before accessing Event!" />
    </div>
    <div id="eventBackground" v-else :style="{ backgroundColor: event.secondaryColor }">
      <!--      :style="
        !empty(event.backgroundImage) && {
          background: `url(${event.backgroundImage}) no-repeat 50% 50%`,
          backgroundSize: 'cover',
        }
      "
-->
      <v-dialog v-model="dialog" persistent :max-width="isDesktop ? '600px' : '90vw'">
        <v-card>
          <v-card-title>
            <span class="headline"> Breakout Room {{ conferenceSelected.title }} </span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="conferenceFormValid" lazy-validation @submit.prevent="doSubmitPinCode">
              <v-text-field label="PIN code" v-model="pinCode" :rules="[v => !!v || 'PIN code is required']" required :color="colorFromEvent"> </v-text-field>
              <Alert v-if="!empty(errorConference)" :message="errorConference" />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="doCloseDialog"> Close </v-btn>
            <v-btn color="blue darken-1" text @click="doSubmitPinCode" :disabled="!conferenceFormValid"> Submit </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="d-flex flex-column container-event" :class="[isDesktop && 'pa-3', device, empty(event.backgroundImage) && 'secondary']">
        <div class="header d-flex justify-space-between align-center" :class="!isDesktop && 'mx-4 my-2'">
          <div class="d-flex align-center">
            <div class="logo-container">
              <img contain class="event-logo event-logo-eventpage d-block rounded-lg" v-if="!empty(event.charityLogo)" :src="event.charityLogo" />
              <template v-else>
                <XLogo />
                <span>
                  <p>CHARITY</p>
                  <p>LOGO</p>
                </span>
              </template>
            </div>
            <div class="ml-5" v-if="isDesktop" :style="{ color: event.primaryColour }" id="event_title_container">
              <h2 class="text-uppercase event_name_title">{{ event.name }}</h2>
              <p class="total_raised_title">Total Raised: {{ totaliser }}</p>
            </div>
          </div>

          <div v-if="!empty(sponsorUrl)" class="sponsor d-flex align-center">
            <span id="sponsors_title">Sponsored By:</span>
            <img :src="sponsorUrl" class="ml-3 rounded-lg" :class="sponsorClass" />
          </div>
        </div>

        <div class="text-center mb-2" v-if="!isDesktop" :style="{ color: event.primaryColour }">
          <h3 class="text-uppercase event_name_title">{{ event.name }}</h3>
          <span class="total_raised_title">Total Raised: {{ totaliser }}</span>
        </div>

        <div class="content d-flex" :class="isDesktop ? 'mt-3' : 'flex-column'">
          <div ref="contentVideo" class="content-video" :class="[isDesktop && 'mr-3']">
            <iframe
              v-if="isValidHttpUrl(event.youtubeURL)"
              :src="youtubeUrlWithParams"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              align-self
              :class="[isDesktop ? 'rounded-lg' : 'videoHeight']"
            ></iframe>
            <div class="mt-3 d-flex justify-space-between align-end">
              <div class="button-left d-flex align-start" :class="[!isDesktop && 'w-full justify-space-between px-4']">
                <v-btn
                  id="button_one"
                  class="three_buttons"
                  :color="colorFromEvent"
                  text
                  elevation="4"
                  rounded
                  block
                  v-if="!empty(getBidInfo.name)"
                  @click="openLink(getBidInfo.url, getBidInfo.name, 'button1Clicked')"
                  >{{ getBidInfo.name }}</v-btn
                >
                <v-btn
                  id="button_two"
                  class="three_buttons"
                  :color="colorFromEvent"
                  text
                  elevation="4"
                  rounded
                  block
                  v-if="!empty(getDonateInfo.name)"
                  @click="openLink(getDonateInfo.url, getDonateInfo.name, 'button2Clicked')"
                >
                  {{ getDonateInfo.name }}
                </v-btn>
                <v-btn
                  id="button_three"
                  class="three_buttons"
                  :color="colorFromEvent"
                  text
                  elevation="4"
                  rounded
                  block
                  v-if="!empty(getBuyTicketInfo.name)"
                  @click="openLink(getBuyTicketInfo.url, getBuyTicketInfo.name, 'button3Clicked')"
                >
                  {{ getBuyTicketInfo.name }}
                </v-btn>
                <v-menu offset-y open-on-hover top transition="slide-y-transition" v-if="isDesktop && isValidConference">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn :color="colorFromEvent" text elevation="4" rounded block v-bind="attrs" v-on="on">
                      {{ event.id == 'WCMHC' ? 'Serena Q&A' : 'Breakout Rooms' }}
                    </v-btn>
                  </template>

                  <v-list
                    :style="{
                      maxHeight: '400px',
                      overflowY: 'auto',
                    }"
                  >
                    <v-list-item
                      v-for="(item, index) in conferenceItems"
                      :key="index"
                      link
                      :style="{
                        background: '#fff',
                      }"
                    >
                      <v-list-item-title
                        :title="item.title"
                        :style="{
                          color: colorFromEvent,
                        }"
                        @click="onOpenConference(item)"
                      />
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <div v-if="isDesktop" class="d-flex align-end">
                <img
                  id="qrcode"
                  v-if="!empty(event.qrCode)"
                  :src="event.qrCode"
                  :style="{
                    maxHeight: '80px',
                    marginRight: !isOpenSupport ? '20px' : '0',
                  }"
                />
                <v-btn :plain="isOpenSupport" elevation="4" rounded block class="text-capitalize help_button" :color="event.primaryColour" @click="() => doCreateConversation(false)">
                  Public chat
                </v-btn>

                <v-btn elevation="4" :plain="!isOpenSupport" rounded block class="text-capitalize help_button" :color="event.primaryColour" @click="() => doCreateConversation(true)">
                  Support chat
                </v-btn>
                <Polling
                  v-show="canShowPoll"
                  :styleButton="{
                    color: event.primaryColour,
                  }"
                  :key="currentPolling.id"
                  :pollDetail="currentPolling"
                  :answers="pollAnswerData"
                  :ready="pollReady"
                  :time="pollingTime"
                  :openImmediate="showPooling"
                  @onGetAnswer="doGetAnswer(currentPolling)"
                />
              </div>
            </div>
            <v-menu offset-y open-on-hover top transition="slide-y-transition" v-if="!isDesktop && isValidConference">
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  :style="{
                    color: colorFromEvent,
                  }"
                  elevation="4"
                  rounded
                  block
                  v-bind="attrs"
                  v-on="on"
                  class="mt-2 mx-4"
                >
                  {{ event.id == 'WCMHC' ? 'Serena Q&A' : 'Breakout Rooms' }}
                </v-btn>
              </template>

              <v-list
                :style="{
                  maxHeight: '400px',
                  overflowY: 'auto',
                }"
              >
                <v-list-item
                  v-for="(item, index) in conferenceItems"
                  :key="index"
                  link
                  :style="{
                    background: '#fff',
                  }"
                >
                  <v-list-item-title
                    :title="item.title"
                    :style="{
                      color: colorFromEvent,
                    }"
                    @click="onOpenConference(item)"
                  >
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div
            class="ticker-area blue-grey darken-4 my-3"
            :class="[!isDesktop && 'relative']"
            v-if="!isDesktop && isShowBottomBar"
            ref="topChat"
            v-click-outside="() => handleMarquee(false)"
            @click="handleMarquee()"
            :style="{
              height: '2rem',
            }"
          >
            <dynamic-marquee :hoverPause="false" :pause="marqueePause" :speed="{ type: 'pps', number: 120 }" direction="row" :repeat="false" :reverse="true">
              <span v-html="marqueeMessageString" />
            </dynamic-marquee>
          </div>
          <v-card
            id="chat_area"
            ref="chat"
            class="d-flex flex-column rounded-lg grey darken-4 white--text"
            align-self
            rounded
            :class="[isDesktop ? 'chatbox' : 'justify-content-center mx-2 chatbox-transition', !isDesktop && !isShowBottomBar && 'mt-3']"
          >
            <p class="chatbox-header grey darken-3 pa-3 ma-0 d-flex justify-space-between align-center">
              <span v-text="isOpenSupport ? 'Support Chat' : 'Comments'" />
              <span class="mr-4 close" v-if="isOpenSupport" @click="isOpenSupport = false"> Close </span>
            </p>
            <div class="chatbox-content px-3" ref="chatContent">
              <div class="chatDetail" :class="[!isOpenSupport && 'show']">
                <template
                  v-for="(mess, index) in orderBy(
                    event.isNeedModConfirmComment ? filter(messageData, item => item.status !== 'DELETED') : filter(messageData, item => item.status === 'APPROVED'),
                    ['createdDate'],
                    ['asc'],
                  )"
                >
                  <p v-if="getMessageStatus(mess).show" :key="index">
                    <template v-if="getMessageStatus(mess).isMyMessage">
                      <b class="white--text"> You: </b>
                      <span v-if="mess.status === 'WAITING' && event.isNeedModConfirmComment" v-html="'[⁉] Comment awaiting moderation'" class="pl-2" />
                      <span v-else-if="mess.status === 'REJECTED'" v-html="'⊘ [Comment was rejected]'" class="pl-2" style="text-decoration: line-through" />
                      <span v-else v-html="convertUrlToLink(decodeBase64(mess.content))" class="pl-2" />
                    </template>
                    <template v-else-if="getMessageStatus(mess).isAnOtherMessage && mess.status === 'APPROVED'">
                      <b v-text="`${mess.name}:`" />
                      <span v-html="convertUrlToLink(decodeBase64(mess.content))" class="pl-2" />
                    </template>
                  </p>
                </template>
              </div>
              <div class="chatDetail" :class="[isOpenSupport && 'show']">
                <p :key="index" v-for="(mess, index) in orderBy(supportData, ['createdAt'], ['asc'])">
                  <b v-text="userInfo.id === mess.userName ? 'You:' : 'Support:'" />
                  <span v-html="convertUrlToLink(decodeBase64(mess.body))" class="pl-2" />
                </p>
              </div>
            </div>
            <input v-model="inputText" class="chatbox-send border ma-2 rounded-pill pa-2" type="text" placeholder="Send a message" @keyup.enter="doCreateMessage" />
          </v-card>

          <div class="d-flex justify-space-between align-center" v-if="!isDesktop">
            <v-btn :plain="isOpenSupport" elevation="4" rounded block class="text-capitalize my-3 mx-2 help_button" style="min-width: auto !important" @click="() => doCreateConversation(false)">
              Public chat
            </v-btn>
            <v-btn
              ref="bottomChat"
              :plain="!isOpenSupport"
              elevation="4"
              rounded
              block
              class="text-capitalize my-3 mx-2 help_button"
              style="min-width: auto !important"
              @click="() => doCreateConversation(true)"
            >
              Support chat
            </v-btn>
          </div>
        </div>
      </div>

      <div
        class="ticker-area blue-grey darken-4 w-full"
        v-if="isDesktop && isShowBottomBar"
        :style="{
          height: '2rem',
        }"
      >
        <dynamic-marquee :speed="{ type: 'pps', number: 120 }" direction="row" :repeat="false" :reverse="true">
          <span v-html="marqueeMessageString" />
        </dynamic-marquee>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { objToQueryString } from '@/helpers/misc.js';
import Alert from '@/components/alert';
import XLogo from '@/assets/images/XLogo';
import GiveryServie from '@/services/GiveryService';
import EventService from '@/services/EventService';
import TrackingService from '@/services/TrackingService';
import { get, orderBy, filter, forEach, findIndex, map } from 'lodash';
import { empty, convertUrlToLink, decodeBase64, encodeBase64, isValidHttpUrl } from '@/utils';
import MessagesMutation from '@/graphql/muation/Messages';
import ConversationMuation from '@/graphql/muation/Conversation';
import MessagesQuery from '@/graphql/query/Messages';
import MessagesSubcription from '@/graphql/subscription/Messages';
import EventSubcription from '@/graphql/subscription/Event';
import CommentQuery from '@/graphql/query/Comment';
import CommentSubcription from '@/graphql/subscription/Comment';
import { PollingQuery } from '@/graphql/query/Polling';
import { PollSubcription, AnswerPollSubcription } from '@/graphql/subscription/Polling';
import PollingService from '@/services/PollingService';
import Polling from './Polling';
import moment from 'moment';
import DynamicMarquee from 'vue-dynamic-marquee';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'EventViewer',
  components: {
    Alert,
    XLogo,
    Polling,
    DynamicMarquee,
  },
  directives: {
    ClickOutside,
  },
  props: {
    device: {
      type: String,
    },
    orientation: {
      type: String,
    },
    contentHeight: {
      type: Number,
    },
  },
  data() {
    return {
      pinCode: '',
      conferenceFormValid: false,
      dialog: false,
      conferenceItems: [],
      conferenceSelected: '',
      errorConference: '',
      ticker: 0,
      totaliser: '',
      bids: [],
      donations: [],
      invalidEvent: false,
      loadingApi: false,
      marqueePause: false,
      marqueeMessageString: '',
      derp: 'blue',
      messageData: [],
      inputText: '',
      supportConversationId: '',
      isOpenSupport: false,
      enableSupportTab: false,
      sponsorClass: '',
      monitorInfo: {
        visitedAt: new Date(),
        numberOf: {
          button1Clicked: 0,
          button2Clicked: 0,
          button3Clicked: 0,
          chatMessage: 0,
          chatHelp: 0,
        },
      },
      currentPolling: {},
      pollAnswerData: [],
      pollingList: [],
      pollingTicker: {},
      pollingTime: 0,
      pollReady: false,
      showPooling: false,
      firstLoad: true,
    };
  },
  apollo: {
    messageData: {
      query: CommentQuery,
      variables() {
        return {
          eventId: this.event_id,
        };
      },
      update: ({ getComments }) => getComments.items,
      subscribeToMore: {
        document: CommentSubcription,
        variables() {
          return {
            eventId: this.event_id,
          };
        },
        updateQuery: (
          previousResult,
          {
            // subscriptionData,
            subscriptionData: {
              data: { onUpdateComment },
            },
          },
        ) => {
          const messIndex = findIndex(previousResult.getComments.items, item => item.id === onUpdateComment.id);
          if (messIndex < 0) {
            previousResult.getComments.items.push(onUpdateComment);
          } else {
            previousResult.getComments.items.splice(messIndex, 1, onUpdateComment);
          }
        },
      },
    },
    supportData: {
      query: MessagesQuery,
      variables() {
        return {
          conversationId: this.supportId,
        };
      },
      update: ({ getMessages }) => getMessages.items,
      subscribeToMore: [
        {
          document: MessagesSubcription,
          variables() {
            return {
              conversationId: this.supportId,
            };
          },
          updateQuery: (
            previousResult,
            {
              subscriptionData: {
                data: { onAddMessage },
              },
            },
          ) => {
            const messIndex = findIndex(previousResult.getMessages.items, item => item.id === onAddMessage.id);
            if (messIndex < 0) {
              previousResult.getMessages.items.push(onAddMessage);
            } else {
              previousResult.getMessages.items.splice(messIndex, 1, onAddMessage);
            }
          },
          client: 'supportClient',
        },
      ],
      // Disable the query
      skip: true,
      client: 'supportClient',
    },
    pollData: {
      query: PollingQuery,
      variables() {
        return {
          eventId: this.event_id,
        };
      },
      update: ({ getEventPoll }) =>
        map(getEventPoll.items, item => ({
          ...item,
          question: JSON.parse(item.question),
        })),
      subscribeToMore: [
        {
          document: PollSubcription,
          variables() {
            return {
              eventId: this.event_id,
            };
          },
          result() {
            this.firstLoad = false;
          },
          // updateQuery: (
          //   previousResult,
          //   {
          //     subscriptionData: {
          //       data: { onUpdatePollEvent },
          //     },
          //   },
          // ) =>
          //   // previousResult,
          //   {
          //     const pollIndex = findIndex(
          //       previousResult.getEventPoll.items,
          //       item => item.id === onUpdatePollEvent.id,
          //     );
          //     if (pollIndex === -1) {
          //       previousResult.getEventPoll.items.push(onUpdatePollEvent);
          //     } else {
          //       previousResult.getEventPoll.items[pollIndex] =
          //         onUpdatePollEvent;
          //     }
          //     return previousResult;
          //   },
        },
      ],
      // Disable the query
      // skip: true,
    },
    $subscribe: {
      pollAnswerData: {
        query: AnswerPollSubcription,
        variables() {
          return {
            pollId: this.currentPolling.id,
          };
        },
        // Disable the query
        skip: true,
        result({ data: { onUpdatePollAnswerEvent } }) {
          this.pollAnswerData.push(onUpdatePollAnswerEvent);
        },
        error(error) {
          console.log('AnswerPollSubcription', { error });
        },
      },
      eventUpdatedData: {
        query: EventSubcription,
        variables() {
          return {
            id: this.event_id,
          };
        },
        result() {
          this.$store.dispatch('fetchEvent', [this.eventParams.eventId, this.eventParams.password, false]);
        },
        error(error) {
          console.log('EventSubcription', { error });
        },
      },
      pollCreatedData: {
        query: PollSubcription,
        variables() {
          return {
            eventId: this.event_id,
          };
        },
        result() {
          this.$apollo.queries.pollData.refetch();
        },
        error(error) {
          console.log('pollCreatedData', { error });
        },
      },
    },
  },
  watch: {
    messageData() {
      if (!this.invalidEvent) {
        this.$nextTick(() => {
          const divMessage = this.$refs.chatContent;
          divMessage.scrollTop = divMessage.scrollHeight;
        });
      }
    },
    isOpenSupport() {
      if (!this.invalidEvent) {
        this.$nextTick(() => {
          const divMessage = this.$refs.chatContent;
          divMessage.scrollTop = divMessage.scrollHeight;
        });
      }
    },
    isDesktop: {
      handler(value) {
        this.doDetectChatBox();
        this.monitorInfo = {
          ...this.monitorInfo,
          deviceType: value ? 'PC' : 'MOBILE',
        };
      },
      immediate: true,
    },
    orientation() {
      this.doDetectChatBox();
    },
    marqueeMessageString() {
      this.doDetectChatBox();
    },
    pollData(value) {
      this.pollingList = filter(value, item => item.status === 'ACTIVE');
    },
    pollingList(value) {
      if (empty(value)) {
        this.currentPolling = {};
        this.pollReady = false;

        if (!empty(this.pollingTicker)) {
          forEach(this.pollingTicker, poll => clearInterval(poll));
        }
      } else {
        this.currentPolling = get(value, '[0]', {});
        // forEach(value, poll => {
        //   const startTime = moment(poll.startTime);
        //   const endTime = moment(poll.endTime);
        //   const currentTime = moment();
        //   if (currentTime.isSameOrBefore(startTime, 'seconds')) {
        //     clearInterval(this.pollingTicker[poll.id]);
        //     this.pollingTicker = {
        //       ...this.pollingTicker,
        //       [poll.id]: setInterval(() => {
        //         if (startTime.isSame(moment(), 'minutes')) {
        //           this.currentPolling = poll;
        //           this.pollingTime = endTime.diff(moment(), 'seconds');
        //           this.pollingEnded = false;
        //           this.onCountingToEnd(poll.id, startTime, endTime);
        //           clearInterval(this.pollingTicker[poll.id]);
        //         } else {
        //           this.pollReady = false;
        //         }
        //       }, 1000),
        //     };
        //   } else if (currentTime.isSameOrBefore(endTime, 'seconds')) {
        //     this.currentPolling = poll;
        //     this.pollingEnded = false;
        //   } else {
        //     this.currentPolling = poll;
        //     // this.pollReady = true;
        //     this.pollingEnded = true;
        //     this.pollingTime = 0;
        //     clearInterval(this.pollingTicker[`${poll.id}-running`]);
        //   }
        //   if (currentTime.isBetween(startTime, endTime, 'seconds')) {
        //     this.pollingTime = endTime.diff(moment(), 'seconds');
        //     this.onCountingToEnd(poll.id, startTime, endTime);
        //   }
        // });
      }
    },
    // pollAnswerData() {
    //   this.pollReady = true;
    // },

    async currentPolling(value, preValue) {
      this.pollReady = false;
      if (!empty(value)) {
        const startTime = moment(value.startTime);
        this.pollingTime = moment().diff(startTime, 'seconds');
        this.onCountingToEnd(value.id, startTime);

        const clientInfo = get(this.pollingId, `${this.event_id}.${value.id}`, {});
        // if (clientInfo.isSubmitted && !value.isPublicResult) {
        //   this.pollReady = false;
        //   return;
        // }

        if (empty(clientInfo.clientId)) {
          await this.$store.dispatch('setPolling', [value.id, this.event_id]);
        }

        await this.doGetAnswer(value);
        if ((empty(preValue) || value.id === preValue.id) && value.status === 'ACTIVE' && !this.firstLoad) {
          this.showPooling = true;
        }
        this.pollReady = true;
        // if (value.isPublicResult) {
        //   const resp = await PollingService.getPollingAnswer(this.event_id, value.id);
        //   this.pollAnswerData = resp.data;
        // } else {
        //   this.pollAnswerData = [];
        // }

        // console.log('getPollingAnswer by API', { ...this.pollAnswerData });
        this.$apollo.subscriptions.pollAnswerData.skip = !value.isPublicResult;
      } else {
        this.$apollo.subscriptions.pollAnswerData.skip = true;
      }
    },

    event: {
      async handler(value) {
        if (!empty(value)) {
          try {
            this.conferenceItems = this.event.options || [];
            switch (value.eventMode) {
              case 'MESSAGE':
                this.marqueeMessageString = value.eventModeMessage;
                break;
              case 'DONATE':
                this.marqueeMessageString = '';
                await this.callGiveryAPIs();
                this.callGiveryInteval = setInterval(this.callGiveryAPIs, 5000);
                break;
            }
          } catch (error) {
            console.log({ error });
          } finally {
            this.doDetectChatBox();
          }
        }
      },
      immediate: true,
    },
    isGetDonate(value) {
      if (!value) {
        clearInterval(this.callGiveryInteval);
      }
    },
    tickerItems(value) {
      if (this.isGetDonate) {
        let messageDonate = '';
        forEach(value, item => {
          messageDonate += `
          <span
            cols="40"
            style="margin-left: 20px"
          >
            <span id="ticker_type">
              <b> ${item.type}&nbsp; </b>
            </span>
            <span id="ticker_name">${item.guestName}</span>:
            <span id="ticker_title"> ${item.title} </span>
            <span id="ticker_amount"> ${item.amount}</span>
          </span>
        `;
        });
        this.marqueeMessageString = messageDonate;
      }
    },
  },

  computed: {
    ...mapState({
      event: 'event',
      eventParams: 'eventParams',
      userEventInfo: 'userInfo',
      supportEventId: 'supportId',
      trackingEventId: 'trackingId',
      pollingId: 'pollId',
      eventColor: 'eventColor',
    }),
    colorFromEvent() {
      const { primaryColour, secondaryColor } = this.eventColor;
      return primaryColour == '#ffffff' ? secondaryColor : primaryColour;
    },
    userInfo() {
      return this.userEventInfo[this.event_id];
    },
    trackingId() {
      return this.trackingEventId[this.event_id];
    },
    supportId() {
      return this.supportEventId[this.event_id];
    },
    isOverScreen() {
      return window.screen.height < this.contentHeight;
    },
    event_id() {
      return this.$route.params.event_id;
    },
    isShowBottomBar() {
      return get(this.event, 'settings.bottomBar', 'SHOW') === 'SHOW' && !empty(get(this.event, 'eventMode')) && !empty(this.marqueeMessageString);
    },
    isGetDonate() {
      return get(this.event, 'eventMode') === 'DONATE';
    },
    isPaidEvent() {
      return get(this.event, 'isPaidEvent', false);
    },
    youtubeUrlWithParams() {
      const params = {
        autoplay: '1',
        controls: '0',
        iv_load_policy: '3',
        modestbranding: '1',
        rel: '0',
      };
      return this.event.youtubeURL + '?' + objToQueryString(params);
    },
    tickerItems() {
      let tickerItems = [];
      for (let i = 0; i < this.bids.length; i++) {
        tickerItems.push({
          ...this.bids[i],
          type: 'Bid',
        });
        // console.log(this.bids[i]);
      }
      for (let i = 0; i < this.donations.length; i++) {
        tickerItems.push({
          ...this.donations[i],
          type: 'Donation',
        });
      }
      tickerItems = tickerItems.sort((a, b) => {
        return b.created - a.created;
      });
      return tickerItems;
    },
    getBidInfo() {
      const bidInfo = this.event.bidUrl;
      return {
        name: get(bidInfo, 'name', 'bid'),
        url: get(bidInfo, 'url'),
      };
    },
    getDonateInfo() {
      const donateInfo = this.event.donateUrl;
      return {
        name: get(donateInfo, 'name', 'bid'),
        url: get(donateInfo, 'url'),
      };
    },
    getBuyTicketInfo() {
      const buyTicketInfo = this.event.buyTicketUrl;
      return {
        name: get(buyTicketInfo, 'name', 'bid'),
        url: get(buyTicketInfo, 'url'),
      };
    },
    isDesktop() {
      return this.device === 'desktop';
    },
    isValidConference() {
      return this.conferenceItems.length > 0;
    },
    sponsorUrl() {
      const sponsorLogo = this.event.sponsorLogo;
      if (!empty(sponsorLogo)) {
        return get(sponsorLogo, `[${this.ticker % sponsorLogo.length}]`);
      }
      return '';
    },
    canShowPoll() {
      return this.pollReady && this.currentPolling?.status === 'ACTIVE';
      // && get(this.pollingId, `${this.event_id}.${this.currentPolling.id}.isSubmitted`, false) && this.currentPolling.isPublicResult;
    },
    viewerName() {
      const { name, firstName, lastName } = this.userInfo;
      return empty(name) ? `${firstName} ${lastName}` : name;
    },
  },
  async created() {
    if (this.event_id !== this.event.id) {
      this.invalidEvent = true;
    } else {
      this.$nextTick(() => {
        if (!this.isDesktop) {
          this.$refs.chat.$el.style.height = '400px';
        }
      });
      if (empty(this.trackingId)) {
        await this.$store.dispatch('setTracking', this.event_id);
      }
      const { email } = this.userInfo || {};
      this.monitorInfo = {
        ...this.monitorInfo,
        id: this.trackingId,
        viewerName: this.viewerName,
        viewerEmail: email,
      };
      this.trackingInfo = {
        eventId: this.event_id,
        id: this.trackingId,
        fullName: this.viewerName,
        email,
      };
      this.doMonitorEvent();
      if (!empty(this.event.sponsorLogo) && this.event.sponsorLogo.length > 1) {
        this.tickerInteval = setInterval(this.doChangeSponsor, 5000);
      }
      this.trackingInteval = setInterval(this.doMonitorEvent, 5000);
    }
  },
  destroyed() {
    if (this.tickerInteval) {
      clearInterval(this.tickerInteval);
    }
    if (this.trackingInteval) {
      clearInterval(this.trackingInteval);
    }
    if (!empty(this.pollingTicker)) {
      forEach(this.pollingTicker, poll => clearInterval(poll));
    }
    if (this.callGiveryInteval) {
      clearInterval(this.callGiveryInteval);
    }
  },
  methods: {
    orderBy,
    empty,
    filter,
    convertUrlToLink,
    decodeBase64,
    isValidHttpUrl,
    async doGetAnswer(poll) {
      try {
        const resp = await PollingService.getPollingAnswer(this.event_id, poll.id);
        this.pollAnswerData = resp.data;
      } catch (_) {
        this.pollAnswerData = [];
      }
    },
    getMessageStatus(mess = {}) {
      const isMyMessage = this.userInfo.email === mess.email;
      const isAnOtherMessage = !this.event.isNeedModConfirmComment || mess.status !== 'WAITING';
      return {
        show: isMyMessage || isAnOtherMessage,
        isMyMessage,
        isAnOtherMessage,
      };
    },
    async callGiveryAPIs() {
      if (!empty(this.event.givergyAPI) && this.isGetDonate) {
        const givergyAPI = JSON.parse(this.event.givergyAPI);
        const donations = await GiveryServie.getDonations(givergyAPI.latestDonationsUrl, givergyAPI.apiKey);
        const bids = await GiveryServie.getBids(givergyAPI.latestBidsUrl, givergyAPI.apiKey);
        const totals = await GiveryServie.getTotaliser(givergyAPI.totaliserUrl, givergyAPI.apiKey);
        this.totaliser = totals.entity.formattedAmount;
        this.bids = bids.entity;
        this.donations = donations.entity;
      } else {
        clearInterval(this.callGiveryInteval);
      }
    },
    handleMarquee(isPaused = true) {
      this.$nextTick(() => (this.marqueePause = isPaused));
    },
    onCountingToEnd(pollId, startTime) {
      clearInterval(this.pollingTicker[`${pollId}-running`]);
      this.pollingTicker = {
        ...this.pollingTicker,
        [`${pollId}-running`]: setInterval(() => {
          // this.pollingTime = endTime.diff(moment(), 'seconds');
          this.pollingTime = moment().diff(startTime, 'seconds');
        }, 1000),
      };
    },
    doCloseDialog() {
      this.$refs.form.reset();
      this.errorConference = '';
      this.dialog = false;
    },
    onOpenConference(conference) {
      if (!conference.isPinCodeRequired) {
        window.open(conference.url, '_blank');
        return;
      }
      this.dialog = true;
      this.conferenceSelected = conference;
    },
    async doSubmitPinCode() {
      try {
        this.errorConference = '';
        await this.$refs.form.validate();
        const resp = await EventService.validateConference(this.event_id, this.conferenceSelected.id, this.pinCode);
        window.open(resp.data.url, '_blank');
        this.doCloseDialog();
      } catch (error) {
        this.errorConference = get(error, 'response.data.message', error.message);
        console.log({ error });
      }
    },
    doMonitorEvent() {
      // if (this.event.isConclude) {
      //   return;
      // }
      this.monitorInfo = {
        ...this.monitorInfo,
        totalVisitedTime: new Date().getTime() - this.monitorInfo.visitedAt.getTime(),
      };
      TrackingService.doMonitorEvent(this.event_id, this.monitorInfo);
      TrackingService.doTrackingEvent(this.event_id, this.trackingInfo);
    },
    doChangeSponsor() {
      this.sponsorClass = '';
      setTimeout(() => {
        this.ticker++;
        this.sponsorClass = 'changed';
      }, 50);
    },
    openLink(url, title, buttonType) {
      const { numberOf } = this.monitorInfo;
      this.monitorInfo = {
        ...this.monitorInfo,
        numberOf: {
          ...numberOf,
          [buttonType]: numberOf[buttonType] + 1,
        },
      };
      this.$gtag.event('Clicked ' + title, {
        method: 'Google',
        url: url,
        button_title: title,
      });
      window.open(url, '_blank');
    },
    async doCreateConversation(openSupport) {
      /// temporary pause function to work around race condition
      function pause(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
      }
      this.isOpenSupport = openSupport;
      /// temporary code modification to resolve support chat loading issue
//      if (openSupport) {
//        return;
//      }
      if (!this.enableSupportTab && !this.loadingApi) {
        this.loadingApi = true;
        console.log(this.supportId);
        if (empty(this.supportId)) {
          const { id } = this.userInfo;
          const newConversation = {
            eventId: this.event_id,
            name: this.viewerName,
            partner: id,
          };
          const {
            data: {
              createConversation: { id: conversationId },
            },
          } = await this.$apollo.mutate({
            mutation: ConversationMuation,
            variables: newConversation,
          });
          await pause(3000);
          console.log(newConversation);
          await this.$store.dispatch('fetchSupportConversation', [conversationId, this.event_id]);
        }
        this.$apollo.queries.supportData.skip = false;
        this.$apollo.queries.supportData.refetch();
        this.enableSupportTab = true;
        this.loadingApi = false;
      }
    },
    doCreateMessage() {
      if (!empty(this.inputText) && !this.loadingApi) {
        this.loadingApi = true;
        const { numberOf } = this.monitorInfo;
        let type = 'chatMessage';
        const { id, email } = this.userInfo;
        const sender = this.viewerName;
        try {
          if (this.isOpenSupport) {
            type = 'chatHelp';
            this.$gtag.event('Sent support message', { method: 'Google' });
            const newMessage = {
              conversationId: this.isOpenSupport ? this.supportId : this.event_id,
              body: encodeBase64(this.inputText),
              sender,
              userName: id,
            };
            this.$apollo.mutate({
              mutation: MessagesMutation,
              variables: newMessage,
              client: 'supportClient',
            });
          } else {
            this.$gtag.event('Posted a comment', { method: 'Google' });
            try {
              EventService.createComment(this.event_id, {
                content: encodeBase64(this.inputText),
                name: sender,
                email,
              }).then(newComment => {
                const cache = this.$apollo.getClient().store.getCache();
                const data = cache.readQuery({
                  query: CommentQuery,
                  variables: {
                    eventId: this.event_id,
                  },
                });
                data.getComments.items.push({
                  ...newComment.data,
                  __typename: 'Comment',
                });
                cache.writeQuery({
                  query: CommentQuery,
                  variables: {
                    eventId: this.event_id,
                  },
                  data,
                });
              });
            } catch (error) {
              console.log({ error });
            }
          }
        } catch (error) {
          console.log({ error });
        } finally {
          this.monitorInfo = {
            ...this.monitorInfo,
            numberOf: {
              ...numberOf,
              [type]: numberOf[type] + 1,
            },
          };

          this.inputText = '';
          this.loadingApi = false;
        }
      }
    },
    doDetectChatBox() {
      setTimeout(() => {
        if (!this.invalidEvent && this.$refs.chat) {
          if (this.isDesktop) {
            this.$refs.chat.$el.style.height = `calc(100vh - ${this.isShowBottomBar ? 200 : 140}px)`;
            this.$refs.contentVideo.style.height = `calc(100vh - ${this.isShowBottomBar ? 200 : 140}px)`;
          } else if (!this.isOverScreen) {
            // this.$refs.chat.$el.style.height = `calc(100vh - ${this.$refs.topChat.getBoundingClientRect().bottom}px - ${this.$refs.bottomChat.$el.getBoundingClientRect().height}px - 36px)`;
          } else {
            this.$refs.chat.$el.style.height = '250px';
          }
        }
      }, 50);
    },
  },
};
</script>
<style lang="scss" >
.container-event {
  min-height: 100vh;
  height: 100%;
  .header {
    max-height: 100px;
    min-height: 100px;
    img {
      display: block;
      max-height: 100px;
    }
    // .sponsor img {
    //   max-width: 100px;
    // }
    // .sponsor {
    //   img {
    //     &.changed {
    //       animation: slide 0.5s;
    //     }
    //   }
    // }
  }

  .content {
    &-video {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      iframe {
        flex: 1;
        display: block;
        width: 100%;
      }
      .videoHeight {
        display: block;
        flex: none;
        height: 54vw;
      }
      .v-btn--block {
        min-width: 100px !important;
        padding: 16px;
        font-weight: 700;
        margin-right: 10px;
        box-shadow: none;
        height: auto;
        &:last-child {
          margin-right: 0;
        }
      }
      .button-left {
        .v-btn--block {
          background: white;
        }
        .v-select {
          cursor: pointer;
          .v-input__control {
            cursor: pointer;
            width: 190px;
            .v-input__slot {
              cursor: pointer;
              margin-bottom: 0;
              .v-label {
                position: relative !important;
              }
              input {
                cursor: pointer;
                width: 0;
                min-width: 0;
              }
            }
            .v-text-field__details {
              display: none;
            }
          }
        }
      }
    }
    .chatbox {
      overflow: hidden;
      width: 400px;
      &-header {
        .close {
          cursor: pointer;
          &::after {
            content: '×';
            position: absolute;
            right: 8px;
            font-size: 30px;
            top: 2px;
          }
        }
      }

      &-content {
        font-size: 14px;
        flex: 1;
        overflow-y: auto;
        .chatDetail {
          opacity: 0;
          height: 0;
          overflow: hidden;
          transform: translateX(-400px);
          transition: all 0.2s;
          &.show {
            overflow: initial;
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
      &-send {
        outline: 0;
        color: white;
        border: 1px solid #616161;
        &::placeholder {
          color: #616161;
        }
      }
    }
  }
}
.ticker-area {
  position: fixed;
  bottom: 20px;
  overflow: hidden;
  white-space: nowrap;
  background-color: #263238 !important;
  padding-top: 4px;
  padding-bottom: 4px;
  &.relative {
    position: relative;
    bottom: initial;
  }
}
#ticker_title {
  display: none;
}
.chatbox-transition {
  transition: height 0.3s;
}
@media (max-width: 400px) {
  .container-event {
    p {
      margin-bottom: 0;
    }
    .header,
    .header img {
      max-height: 60px;
    }
    .content-video .button-left {
      .v-btn--block {
        padding: 10px;
        min-width: auto !important;
      }
    }
  }
  .content {
    &-video {
      display: block;
    }
  }
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
