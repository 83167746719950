import gql from "graphql-tag";

const EventSubcription = gql`
  subscription MySubscription($id: String!) {
    onEventUpdate(id: $id) {
      id
      message
      updatedAt
    }
  }
`;

export default EventSubcription;
