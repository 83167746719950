import gql from 'graphql-tag';

const CommentSubcription = gql`
  subscription MySubscription($eventId: String!) {
    onUpdateComment(eventId: $eventId) {
      content
      createdDate
      email
      eventId
      id
      lastUpdatedDate
      name
      status
    }
  }
`;

export default CommentSubcription;
