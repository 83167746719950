import { apiClient, handleResp } from './utils';
//const APIKey = 'Daana-API key=U4mQVpQrZN7HT8vGyrEy';
export default {
  async getTotaliser(totaliserUrl, apiKey) {
    return handleResp(
      (
        await apiClient(
          {
            Authorization: 'Daana-API key=' + apiKey,
          },
          totaliserUrl,
        )
      ).get(),
    );
  },
  async getDonations(latestDonationsUrl, apiKey) {
    return handleResp(
      (
        await apiClient(
          {
            Authorization: 'Daana-API key=' + apiKey,
          },
          latestDonationsUrl,
        )
      ).get(),
    );
  },
  async getBids(latestBidsUrl, apiKey) {
    return handleResp(
      (
        await apiClient(
          {
            Authorization: 'Daana-API key=' + apiKey,
          },
          latestBidsUrl,
        )
      ).get(),
    );
  },
  async getLots(lotsUrl, apiKey) {
    return handleResp(
      (
        await apiClient(
          {
            Authorization: 'Daana-API key=' + apiKey,
          },
          lotsUrl,
        )
      ).get(),
    );
  },
};
