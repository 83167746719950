import gql from 'graphql-tag';

const AnswerPollSubcription = gql`
  subscription MySubscription($pollId: String!) {
    onUpdatePollAnswerEvent(pollId: $pollId) {
      answerId
      clientId
      createdDate
      lastUpdatedDate
      pollId
      questionId
    }
  }
`;

const PollSubcription = gql`
  subscription MySubscription($eventId: String!) {
    onUpdatePollEvent(eventId: $eventId) {
      createdDate
      eventId
      id
      lastUpdatedDate
      name
      question
      status
      startTime
      isPublicResult
    }
  }
`;

export { AnswerPollSubcription, PollSubcription };
