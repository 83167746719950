<template>
  <v-dialog v-model="dialog" width="100%" scrollable max-width="500" content-class="flex-column" transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text elevation="4" rounded block :class="['text-capitalize', ready ? 'd-inline-block' : 'd-none']" v-bind="attrs" v-on="on" :style="styleButton"> {{ pollDetail.btnSubmitText }} </v-btn>
    </template>
    <v-card tile v-for="(q, index) in pollDetail.question" :key="index">
      <v-card-title class="primary--text text-h5 grey lighten-3">
        <b>{{ index + 1 }}.</b> &nbsp;{{ q.name }}
      </v-card-title>
      <v-card-text>
        <template v-if="pollDetail.isPublicResult && answered[q.id]">
          <v-progress-linear
            v-for="(answer, indexC) in q.answers"
            :key="answer.id"
            :value="round(((get(stats, `${q.id}.${answer.id}`, 0) || 0) / get(stats, `${q.id}.total`, 0)) * 100, 2)"
            color="secondary"
            height="16"
            :class="indexC === 0 ? 'mt-4' : 'mt-1'"
            class="rounded"
          >
            <template v-slot:default="{ value }">
              <div class="d-flex align-center justify-space-between w-full px-4 primary--text">
                <span v-text="`${indexC + 1}. ${answer.answer}`" />
                <strong v-text="`${value}%`" />
              </div>
              <!-- v-text="`${value}% [${stats[answer.id]}/${answers.length}]`" -->
            </template>
          </v-progress-linear>
        </template>
        <template v-else>
          <template v-if="q.isMultiple">
            <v-checkbox
              v-for="(answer, indexC) in q.answers"
              :disabled="answered[q.id]"
              :key="answer.id"
              v-model="selected[q.id]"
              :label="answer.answer"
              :value="answer.id"
              hide-details
              :class="indexC > 0 && 'pt-1 mt-1'"
            />
          </template>
          <template v-else>
            <v-radio-group v-model="selected[q.id]" hide-details :disabled="answered[q.id]">
              <v-radio v-for="answer in q.answers" :key="answer.id" :label="answer.answer" :value="answer.id" />
            </v-radio-group>
          </template>
        </template>
      </v-card-text>
    </v-card>

    <v-card-actions class="grey lighten-3 d-flex align-center justify-space-between px-6">
      <!-- class="grey lighten-3 d-flex align-center justify-center px-6" -->
      <span class="primary--text font-weight-bold" v-text="time < 0 ? 'Poll closed' : `${timeCountDown.minutes}:${timeCountDown.seconds}`" />
      <v-btn v-if="dialog && !isResult" color="primary" :loading="isLoading" :disabled="isLoading" @click="doSubmitAnswer"> Submit </v-btn>
    </v-card-actions>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import PollingService from '@/services/PollingService';
import { forEach, get, find, countBy, groupBy, round, map, filter } from 'lodash';
import { empty } from '@/utils';
export default {
  props: {
    styleButton: {
      type: Object,
      required: true,
    },
    pollDetail: {
      type: Object,
      default: () => ({}),
    },
    answers: {
      type: Array,
      default: () => [],
    },
    ready: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Number,
      required: true,
    },
    openImmediate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      pollingId: 'pollId',
    }),
    isResult() {
      return map(this.answered, item => item).length === this.pollDetail.question?.length;
    },
    pollClientId() {
      return get(this.pollingId, `${this.pollDetail.eventId}.${this.pollDetail.id}.clientId`);
    },
    canShowPoll() {
      // return !empty(this.pollDetail) && this.ready && (!this.isResult || this.pollDetail.isPublicResult);
      return !empty(this.pollDetail) && this.ready;
    },
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    selected: {},
    answered: {},
    stats: {},
    timeCountDown: {},
  }),
  watch: {
    pollDetail: {
      handler(value) {
        forEach(value.question, item => {
          this.selected = {
            ...this.selected,
            [item.id]: [],
          };
        });
      },
      immediate: true,
    },
    answers(value) {
      forEach(this.pollDetail.question, item => {
        this.selected = {
          ...this.selected,
          [item.id]: [],
        };
      });
      const stats = {};
      forEach(groupBy(value, 'questionId'), (item, key) => {
        stats[key] = {
          ...countBy(item, 'answerId'),
          total: item.length,
        };
      });
      forEach(
        groupBy(
          filter(value, item => item.clientId === this.pollClientId),
          'questionId',
        ),
        (item, questionId) => {
          const answers = map(item, item => item.answerId);
          const question = find(this.pollDetail.question, q => q.id === questionId);
          this.answered = {
            ...this.answered,
            [questionId]: true,
          };
          this.selected = {
            ...this.selected,
            [questionId]: question?.isMultiple ? [...this.selected[questionId], ...answers] : answers[0],
          };
        },
      );
      this.stats = stats;
    },
    time: {
      handler(value) {
        this.calcTime(value * 1000);
      },
      immediate: true,
    },
    openImmediate: {
      handler(value) {
        setTimeout(() => {
          this.dialog = value;
          // || this.pollDetail.isPublicResult;
        }, 300);
      },
      immediate: true,
    },
  },
  methods: {
    round,
    get,
    async doSubmitAnswer() {
      try {
        this.isLoading = true;
        const answers = filter(
          map(this.selected, (answer, questionId) => ({
            answer,
            questionId,
          })),
          item => !empty(item.answer) && !this.answered[item.questionId],
        );
        for (let k = 0; k < answers.length; k++) {
          if (!empty(answers[k].answer)) {
            const question = find(this.pollDetail.question, q => q.id === answers[k].questionId);
            if (question.isMultiple) {
              for (let i = 0; i < answers[k].answer.length; i++) {
                await PollingService.createPollingAnswer(this.pollDetail.eventId, this.pollDetail.id, {
                  answerId: answers[k].answer[i],
                  questionId: answers[k].questionId,
                  clientId: this.pollClientId,
                });
              }
            } else {
              await PollingService.createPollingAnswer(this.pollDetail.eventId, this.pollDetail.id, {
                answerId: answers[k].answer,
                questionId: answers[k].questionId,
                clientId: this.pollClientId,
              });
            }
          }
        }
        await this.$store.dispatch('setPollingSubmitted', [this.pollClientId, this.pollDetail.id, this.pollDetail.eventId]);
        this.$emit('onGetAnswer');
      } catch (error) {
        console.log({ error });
      } finally {
        this.isLoading = false;
      }
    },
    calcTime(dist) {
      // Time calculations for days, hours, minutes and seconds
      const minutes = ~~((dist % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = ~~((dist % (1000 * 60)) / 1000);
      this.timeCountDown = {
        minutes: `${minutes < 10 ? '0' : ''}${minutes}`,
        seconds: `${seconds < 10 ? '0' : ''}${seconds}`,
      };
    },
  },
};
</script>
