import { apiClient, handleResp } from './utils';

export default {
  async doMonitorEvent(eventId, data) {
    return handleResp((await apiClient()).put(`/event/${eventId}/monitor`, data));
  },
  async doTrackingEvent(eventId, data) {
    return handleResp((await apiClient()).put(`/event/${eventId}/tracking`, data));
  },
};
