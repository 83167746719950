import gql from 'graphql-tag';

const PollingAnswerQuery = gql`
  query MyQuery($pollId: String!, $limit: Int!, $nextToken: String) {
    getEventPollAnswer(pollId: $pollId, limit: $limit, nextToken: $nextToken) {
      items {
        answerId
        clientId
        createdDate
        lastUpdatedDate
        pollId
        questionId
      }
      nextToken
    }
  }
`;

const PollingQuery = gql`
  query MyQuery($eventId: String!) {
    getEventPoll(eventId: $eventId) {
      items {
        isPublicResult
        createdDate
        eventId
        id
        btnSubmitText
        lastUpdatedDate
        name
        question
        status
        startTime
      }
    }
  }
`;

export { PollingQuery, PollingAnswerQuery };
