import gql from 'graphql-tag';

const CommentQuery = gql`
  query MyQuery($eventId: String!) {
    getComments(eventId: $eventId, limit: 100) {
      items {
        content
        createdDate
        email
        eventId
        id
        lastUpdatedDate
        name
        status
      }
      nextToken
    }
  }
`;

export default CommentQuery;
